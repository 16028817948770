.nav-bar {
    &__logo {
        
        height: 50px;
        width: 50px;
        margin-right: 40px;
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .MuiTab-root {
        margin-right: 20px;
    }

    .MuiButtonBase-root {
        font-size: 1rem;
        text-transform: none;
        color: inherit;
    }
}