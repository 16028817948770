.dropzone {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #0087F7;
    border-radius: 10px;

    &__container {
        height: 400px;
        width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;

        &--drag-accept {
            .dropzone {
                border-color: #00c264;
                background-color: #d3f9e7;
            }
        }
    }
}